import { Type } from '@angular/core';
import { Exclude, Expose, Transform } from 'class-transformer';

import { MetaType } from '@base/models/classes/meta-type.model';
import { VerticalAlignment } from '@base/models/enums/vertical-alignment.enum';
import { transformToModel } from '@misc/helpers/model-conversion/transform-helpers/transform-to-model.function';
import { AppIcon } from '@models/enums/app.icons';
import { BlockStyleSettings } from '@shared/blocks/models/block-style-settings.model';
import { BlockColumnImage, BlockColumnText } from './block-column.model';

export enum BlockType {
  TEXT = 'text',
  TEXT_TWO_COLUMNS = 'textTwoColumns',
  IMAGE = 'image',
  TEXT_IMAGE = 'textImage',
  IMAGE_TEXT = 'imageText',
  VIDEO = 'video',
  EMBEDDED = 'embedded',
  INFO_BOX = 'infoBox',
  SINGLE_CHOICE = 'singleChoice',
  INPUT = 'input',
  SELECTION = 'selection',
  SLIDER = 'slider',
  QUESTION = 'question',
  ROLE_INPUT = 'roleInput',
  INPUT_SELECTION = 'inputSelection',
  READONLY = 'readonly',
  TABLE = 'table',
  NINE_FIELD_MATRIX = 'nine-field-matrix',
  LISTING = 'listing',
  CLUSTER = 'cluster'
}

export enum InfoBoxIcon {
  QUESTION = 'questionIcon',
  QUESTION_BLACK = 'questionBlackIcon',
  EXCLAMATION = 'exclamationIcon',
  EXCLAMATION_BLACK = 'exclamationBlackIcon',
  DONE = 'doneIcon',
  DONE_BLACK = 'doneBlackIcon',
  ERROR = 'errorIcon',
  ERROR_BLACK = 'errorBlackIcon'
}

export enum InfoBoxImage {
  INFO = AppIcon.INFO_ICON,
  BULB = AppIcon.BULB_ICON,
  DOC = AppIcon.DOC_ICON
}

export const DEFAULT_BLOCK_TYPE = BlockType.TEXT;

export const DYNAMIC_BLOCK_TYPES = [
  BlockType.INPUT,
  BlockType.INPUT_SELECTION,
  BlockType.QUESTION,
  BlockType.ROLE_INPUT,
  BlockType.SELECTION,
  BlockType.SINGLE_CHOICE,
  BlockType.SLIDER,
  BlockType.NINE_FIELD_MATRIX,
  BlockType.TABLE,
  BlockType.LISTING,
  BlockType.CLUSTER
];

export class Block {
  @Expose()
  id: string;
  @Expose()
  @Transform(transformToModel(BlockStyleSettings))
  styleSettings?: BlockStyleSettings;
}

@Exclude()
export class BlockText extends Block {
  @Expose()
  @Transform(({ value }) => value || '')
  content: string;
}

@Exclude()
export class BlockTextTwoColumns extends Block {
  @Expose()
  columns: BlockColumnText[];
}

@Exclude()
export class BlockImage extends Block {
  @Expose()
  fileId: string;
  @Expose()
  @Transform(({ value }) => value || '')
  fileUrl: string;
  @Expose()
  originalId: string;
  @Expose()
  @Transform(({ value }) => value || '')
  originalUrl: string;
  @Expose()
  @Transform(({ value }) => value || '')
  alignment: VerticalAlignment;
}

@Exclude()
export class BlockTextImage extends Block {
  @Expose()
  columns: (BlockColumnText | BlockColumnImage)[];
}

@Exclude()
export class BlockImageText extends Block {
  @Expose()
  columns: (BlockColumnText | BlockColumnImage)[];
}

@Exclude()
export class BlockVideo extends Block {
  @Expose()
  fileId: string;
  @Expose()
  @Transform(({ value }) => value || '')
  fileUrl: string;
  @Expose()
  width: number;
}

@Exclude()
export class BlockEmbedded extends Block {
  @Expose()
  fileId: string;
  @Expose()
  @Transform(({ value }) => value || '')
  fileUrl: string;
  @Expose()
  height: number;
}

@Exclude()
export class BlockInfoBoxButton {
  @Expose()
  show: boolean;
  @Expose()
  label: string;
  @Expose()
  link: string;
}

@Exclude()
export class BlockInfoBox extends Block {
  @Expose()
  photoId: string;
  @Expose()
  photo: string;
  @Expose()
  originalPhotoId: string;
  @Expose()
  originalPhoto: string;
  @Expose()
  text: string;
  @Expose()
  altImage: InfoBoxImage;
  @Expose()
  icon: InfoBoxIcon;
  @Expose()
  showIcon: boolean;
  @Expose()
  CTA1?: BlockInfoBoxButton;
  @Expose()
  CTA2?: BlockInfoBoxButton;
}

@Exclude()
export class BlockTable extends Block {
  @Expose()
  title: string;
  @Expose()
  metaType: MetaType;
  @Expose()
  content: string;
  @Expose()
  description: string;
  @Expose()
  dynamicOptionsBlockId: string;
  @Expose()
  rows: BlockRow[];
  @Expose()
  allowCustomSelection: boolean;
  @Expose()
  dynamicRows: boolean;
  @Expose()
  isInstructionEnabled: boolean;
  @Expose()
  instruction?: string;
}

@Exclude()
export class BlockRow {
  @Expose()
  id: string;

  @Expose()
  values: { [key: string]: string };
}

@Exclude()
export class BlockSingleChoiceAnswer extends BlockText {}

@Exclude()
export class BlockSingleChoice extends Block {
  @Expose()
  question: string;
  @Expose()
  answers: BlockSingleChoiceAnswer[];
  @Expose()
  isInstructionEnabled: boolean;
  @Expose()
  instruction?: string;
}

@Exclude()
export class BlockInputOption {
  @Expose()
  id: string;
  @Expose()
  title: string;
  @Expose()
  label: string;
}

@Exclude()
export class BlockInput extends Block {
  @Expose()
  question: string;
  @Expose()
  options: BlockInputOption[];
  @Expose()
  isInstructionEnabled: boolean;
  @Expose()
  instruction?: string;
}

@Exclude()
export class SelectionBlockOption extends BlockText {
  @Expose()
  isCustom?: boolean;
}

@Exclude()
export class BlockSelection extends Block {
  @Expose()
  title: string;
  @Expose()
  optionsTitle: string;
  @Expose()
  options: SelectionBlockOption[];
  @Expose()
  isOwnOptionsEnabled?: boolean;
  @Expose()
  isInstructionEnabled: boolean;
  @Expose()
  instruction?: string;
}

@Exclude()
export class QuestionBlockOption {
  @Expose()
  id: string;
  @Expose()
  value: string;
  @Expose()
  comment?: string;
  @Expose()
  isCommentEnabled?: boolean;
}

@Exclude()
export class BlockQuestion extends Block {
  @Expose()
  question: string;
  @Expose()
  description?: string;
  @Expose()
  answerOptionsCount: number;
  @Expose()
  answerOptions: QuestionBlockOption[];
  @Expose()
  isDescriptionEnabled?: boolean;
  @Expose()
  isInstructionEnabled: boolean;
  @Expose()
  instruction?: string;
}

export class BlockSliderOption {
  @Expose()
  id: string;
  @Expose()
  title: string;
  @Expose()
  description: string;
  @Expose()
  active: boolean;
}

@Exclude()
export class BlockSliderSettings {
  @Expose()
  showDescription: boolean;
  @Expose()
  showDescriptions: boolean;
  @Expose()
  showTooltip: boolean;
  @Expose()
  showLabels: boolean;
  @Expose()
  showInstruction: boolean;
}

@Exclude()
export class BlockSlider extends Block {
  @Expose()
  question: string;
  @Expose()
  description?: string;
  @Expose()
  options: BlockSliderOption[];
  @Expose()
  labelLeft?: string;
  @Expose()
  labelRight?: string;
  @Expose()
  tooltip?: string;
  @Expose()
  instruction?: string;
  @Expose()
  settings?: BlockSliderSettings;
}

@Exclude()
export class BlockRoleInputValue {
  @Expose()
  id: string;
  @Expose()
  value: string;
}

@Exclude()
export class BlockRoleInputOption {
  @Expose()
  id: string;
  @Expose()
  title: string;
  @Expose()
  occupation: string;
  @Expose()
  labelExpense: BlockRoleInputValue;
  @Expose()
  labelCost: BlockRoleInputValue;
  @Expose()
  labelTotal: BlockRoleInputValue;
}

@Exclude()
export class BlockRoleInput extends Block {
  @Expose()
  question: string;
  @Expose()
  options: BlockRoleInputOption[];
  @Expose()
  isInstructionEnabled: boolean;
  @Expose()
  instruction?: string;
}

@Exclude()
export class InputSelectionBlockOption {
  @Expose()
  id: string;
  @Expose()
  value: string;
}

@Exclude()
export class InputSelectionBlockQuestion {
  @Expose()
  id: string;
  @Expose()
  title: string;
  @Expose()
  options: InputSelectionBlockOption[];
}

@Exclude()
export class BlockInputSelection extends Block {
  @Expose()
  title: string;
  @Expose()
  questions: InputSelectionBlockQuestion[];
  @Expose()
  questionsCount: number;
  @Expose()
  isInstructionEnabled: boolean;
  @Expose()
  instruction?: string;
}

@Exclude()
export class BlockReadonly extends Block {
  @Expose()
  segmentId: string;
}

@Exclude()
export class BlockAnswerOption {
  @Expose()
  id: string;
  @Expose()
  value: string;
  @Expose()
  description?: string;
  @Expose()
  isCustom?: boolean;
  @Expose()
  columnId?: string;
  @Expose()
  groupId?: string;
}

// Nine-field matrix block
@Exclude()
export class BlockNineFieldMatrixAxisConfigItem {
  @Expose()
  id: string;
  @Expose()
  value: string;
}

@Exclude()
export class BlockNineFieldMatrixAxisConfig {
  @Expose()
  min: BlockNineFieldMatrixAxisConfigItem;
  @Expose()
  mid: BlockNineFieldMatrixAxisConfigItem;
  @Expose()
  max: BlockNineFieldMatrixAxisConfigItem;
}

@Exclude()
export class BlockNineFieldMatrixOption {
  @Expose()
  id: string;
  @Expose()
  value: string;
  @Expose()
  x?: number;
  @Expose()
  y?: number;
  @Expose()
  isChecked?: boolean;
  @Expose()
  groupId?: string;
}

@Exclude()
export class BlockNineFieldMatrix extends Block {
  @Expose()
  title: string;
  @Expose()
  description: string;
  @Expose()
  xConfig: BlockNineFieldMatrixAxisConfig;
  @Expose()
  yConfig: BlockNineFieldMatrixAxisConfig;
  @Expose()
  optionsTitle: string;
  @Expose()
  options: BlockNineFieldMatrixOption[];
  @Expose()
  dynamicOptionsBlockId?: string;
  @Expose()
  instruction?: string;
  // Settings
  @Expose()
  isDynamicOptionsEnabled: boolean;
  @Expose()
  isSingleSelectionEnabled: boolean;
  @Expose()
  isMultipleSelectionEnabled: boolean;
  @Expose()
  isInstructionEnabled: boolean;
}

@Exclude()
export class BlockListingInputValue {
  @Expose()
  id: string;
  @Expose()
  value: string;
}

export class BlockListing extends Block {
  @Expose()
  title: string;
  @Expose()
  description: string;
  @Expose()
  titleListSection: string;
  @Expose()
  tooltip: string;
  @Expose()
  isTooltipEnabled: boolean;
  @Expose()
  options: BlockListingInputValue[];
}

@Exclude()
export class BlockClusterOption {
  @Expose()
  id: string;
  @Expose()
  value: string;
  @Expose()
  isCustom: boolean;
}

@Exclude()
export class BlockClusterColumn {
  @Expose()
  id: string;
  @Expose()
  value: string;
  @Expose()
  description: string;
  @Expose()
  isActive: boolean;
  @Expose()
  options: BlockAnswerOption[];
}

@Exclude()
export class BlockCluster extends Block {
  @Expose()
  title: string;
  @Expose()
  description: string;
  @Expose()
  optionsTitle: string;
  @Expose()
  columns: BlockClusterColumn[];
  @Expose()
  options: BlockClusterOption[];
  @Expose()
  dynamicOptionsBlockId?: string;
  @Expose()
  instruction?: string;
  // Settings
  @Expose()
  columnsCount: number;
  @Expose()
  isDynamicOptionsEnabled: boolean;
  @Expose()
  isCustomOptionEnabled: boolean;
  @Expose()
  isInstructionEnabled: boolean;
}

export type AnyBlock =
  | BlockText
  | BlockTextTwoColumns
  | BlockImage
  | BlockTextImage
  | BlockImageText
  | BlockVideo
  | BlockEmbedded
  | BlockInfoBox
  | BlockTable
  | BlockSingleChoice
  | BlockInput
  | BlockSelection
  | BlockSlider
  | BlockQuestion
  | BlockRoleInput
  | BlockInputSelection
  | BlockReadonly
  | BlockNineFieldMatrix
  | BlockListing
  | BlockCluster;

export type AnyColumnsBlock = BlockTextTwoColumns | BlockTextImage | BlockImageText;

export const BlockTypeInstanceMap: Record<BlockType, Type<AnyBlock>> = Object.freeze({
  [BlockType.TEXT]: BlockText,
  [BlockType.TEXT_TWO_COLUMNS]: BlockTextTwoColumns,
  [BlockType.IMAGE]: BlockImage,
  [BlockType.TEXT_IMAGE]: BlockTextImage,
  [BlockType.IMAGE_TEXT]: BlockImageText,
  [BlockType.VIDEO]: BlockVideo,
  [BlockType.EMBEDDED]: BlockEmbedded,
  [BlockType.INFO_BOX]: BlockInfoBox,
  [BlockType.TABLE]: BlockTable,
  [BlockType.SINGLE_CHOICE]: BlockSingleChoice,
  [BlockType.INPUT]: BlockInput,
  [BlockType.SELECTION]: BlockSelection,
  [BlockType.SLIDER]: BlockSlider,
  [BlockType.QUESTION]: BlockQuestion,
  [BlockType.ROLE_INPUT]: BlockRoleInput,
  [BlockType.INPUT_SELECTION]: BlockInputSelection,
  [BlockType.READONLY]: BlockReadonly,
  [BlockType.NINE_FIELD_MATRIX]: BlockNineFieldMatrix,
  [BlockType.LISTING]: BlockListing,
  [BlockType.CLUSTER]: BlockCluster
});

export const INTERACTIVE_BLOCKS: Type<AnyBlock>[] = [
  BlockSingleChoice,
  BlockInput,
  BlockSelection,
  BlockSlider,
  BlockQuestion,
  BlockRoleInput,
  BlockInputSelection,
  BlockNineFieldMatrix,
  BlockListing,
  BlockCluster,
  BlockTable
];

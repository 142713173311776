import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { auditTime } from 'rxjs/operators';

import { BaseFormFieldAbstractComponent } from '@misc/abstracts/base-form-field.abstract.component';
import { AppIcon } from '@models/enums/app.icons';

@Component({
  selector: 'base-autocomplete',
  standalone: true,
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatButtonModule,
    MatAutocompleteModule,
    FormsModule,
    ReactiveFormsModule
  ],
  templateUrl: './base-autocomplete.component.html',
  styleUrls: ['./base-autocomplete.component.scss']
})
export class BaseAutocompleteComponent extends BaseFormFieldAbstractComponent implements OnInit {
  @Output() keywordDefined: EventEmitter<string> = new EventEmitter<string>();
  @Input() options: any[];
  @Input() optionTemplate: TemplateRef<any>;
  @Input() paramName: string = 'name';
  @Input() template: TemplateRef<any>;
  readonly AppIcon = AppIcon;

  @Input() displayWith: (option: any) => any = (option: any): string => {
    return option?.[this.paramName] ?? option;
  };

  ngOnInit(): void {
    this.formControl.valueChanges.pipe(takeUntilDestroyed(this._destroyRef), auditTime(300)).subscribe((query: string): void => {
      this.keywordDefined.emit(query);
    });
  }

  onClear(): void {
    this.formControl.setValue('');
  }

  handleFocus(): void {
    this.keywordDefined.emit(this.formControl.value || '');
  }
}

<div class="block-table-attribute" [class.primary]="attribute().primary">
  <div class="block-table-attribute__top">
    <div>
      <div class="body-6">
        {{ attribute().name }}
      </div>
      <div class="body-2 block-table-attribute__type">
        {{ pageKey + 'TYPES.' + attribute().type | translate }}
      </div>
    </div>
    <div *ngIf="attribute().description" class="block-table-attribute__icon">
      <mat-icon [matTooltip]="attribute().description" [svgIcon]="AppIcon.INFO_20"></mat-icon>
    </div>
  </div>
  <div class="block-table-attribute__bottom">
    <mat-select
      class="block-table-attribute__select"
      [value]="attribute().displayMode"
      (valueChange)="save($event)"
      [disabled]="attribute().primary"
      panelClass="block-table-attribute__select-panel"
    >
      <mat-option value="display">Anzeigen</mat-option>
      <mat-option value="edit">Bearbeiten</mat-option>
      <mat-option value="hide">Ausblenden</mat-option>
    </mat-select>
  </div>
  <!-- {{ attribute().primary }} -->
</div>

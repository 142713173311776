<div class="block-table">
  <div *ngIf="isEditMode">
    <div class="block-common__title">{{ 'BLOCK.TABLE.META_TYPE' | translate }}</div>
    <mat-select
      class="block-table__meta-type"
      [value]="block.metaType"
      (valueChange)="saveMetaType($event)"
      [compareWith]="compareWith"
      [placeholder]="'BLOCK.TABLE.META_TYPE_PLACEHOLDER' | translate"
      panelClass="block-table__select-panel"
    >
      <mat-option *ngFor="let option of metaTypeOptions | async" [value]="option">
        {{ option.name }}
      </mat-option>
    </mat-select>
    <div class="block-common__title">{{ 'BLOCK.TABLE.TITLE' | translate }}</div>
    <app-text-editor (contentChanged)="block.title = $event; save()" [content]="block.title" class="block-common__field"></app-text-editor>
    <div class="block-common__title">{{ 'BLOCK.TABLE.DESCRIPTION' | translate }}</div>
    <app-text-editor
      (contentChanged)="block.description = $event; save()"
      [content]="block.description"
      class="block-common__field"
    ></app-text-editor>
    <ng-container *ngIf="metaTypeControl.value">
      <ng-container *ngIf="hasDynamicRows && block.metaType">
        <div class="block-common__title">{{ 'BLOCK.TABLE.ROWS' | translate }}</div>
        <div class="block-table__selection-id">
          <base-form-input
            [control]="dynamicOptionsBlockIdControl"
            [hideLabel]="true"
            [placeholder]="pageKey + 'DYNAMIC_ROW_PLACEHOLDER' | translate"
            class="block-common__option-input"
          ></base-form-input>
          <mat-icon [svgIcon]="isDynamicOptionsBlockIdValid() ? AppIcon.SUCCESS : AppIcon.WARNING"></mat-icon>
        </div>
        <p class="block-table__hint">{{ pageKey + 'ROWS_HINT' | translate }}</p>
      </ng-container>
      <ng-container *ngIf="!hasDynamicRows && block.metaType">
        <div class="block-selection__options">
          <div class="block-common__title">{{ 'BLOCK.TABLE.ROWS' | translate }}</div>
          <div *ngFor="let row of block.rows; let i = index" class="block-table__option-item">
            <div *ngIf="isEditMode" class="block-common__option-wrap">
              <app-block-id [blockID]="[block.id, row.id]"></app-block-id>
              <app-text-editor
                (dataChanged)="save()"
                [content]="row.values[primaryAttribute.id]"
                class="block-common__field block-selection__field"
              ></app-text-editor>
            </div>

            <button (click)="deleteRow(row, i)" class="block-table__delete-button" color="accent" mat-icon-button>
              <mat-icon [svgIcon]="AppIcon.TRASH"></mat-icon>
            </button>
          </div>

          <div class="block-table__option-item">
            <app-text-editor
              (contentChanged)="newRowName.setValue($event)"
              [changeDelay]="0"
              [content]="newRowName.value"
              [isViewMode]="!isEditMode"
              [placeholder]="'BLOCK.TABLE.OPTION_PLACEHOLDER' | translate"
              class="block-common__field block-table__add-field"
            ></app-text-editor>

            <button (click)="addRow()" class="block-table__add-button" color="primary" mat-flat-button>
              {{ 'BUTTON_NAME.ADD' | translate }}
            </button>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="block.metaType">
        <div class="block-common__title">{{ 'BLOCK.TABLE.COLUMNS' | translate }}</div>
        <div class="block-table__attributes-container">
          <app-block-table-attribute
            *ngFor="let attribute of block.metaType?.attributes"
            [attribute]="attribute"
            (saveAttribute)="attribute.displayMode = $event; save()"
          ></app-block-table-attribute>
        </div>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="showInstruction">
      <div class="block-common__title">{{ 'BLOCK.TABLE.INSTRUCTION' | translate }}</div>
      <app-text-editor
        (contentChanged)="block.instruction = $event; save()"
        [content]="block.instruction"
        [isViewMode]="!isEditMode"
        class="block-common__field"
      ></app-text-editor>
    </ng-container>
  </div>
  <div *ngIf="!isEditMode" class="block-table__view" [ngStyle]="blockViewStyles">
    <app-block-table-view
      [isUserInputDisabled]="isUserInputDisabled"
      (saveAnswer)="saveAnswer($event)"
      [rows]="block.rows"
      [block]="block"
      [answerView]="answerView"
      [allowCustomSelection]="allowCustomSelection"
      [showInstruction]="showInstruction"
    ></app-block-table-view>
  </div>
</div>

<ng-template #settingTemplate>
  <app-settings (save)="saveSetting()" [title]="('SETTINGS.TITLE' | translate) + ': ' + ('BLOCK_NAME.TABLE' | translate)">
    <app-block-cql-settings [segments]="allSegments" [cql]="segment.cql"></app-block-cql-settings>
    <app-block-playbook-settings [isInPlaybook]="segment.isInPlaybook"></app-block-playbook-settings>

    <div class="settings__block">
      <h4 class="body-1 settings__title">
        <span>{{ 'BLOCK.TABLE.SETTINGS.DYNAMIC_ROWS_TOGGLE' | translate }}</span>
        <base-boolean-field
          [disabled]="allowCustomSelectionControl.value"
          [control]="dynamicRowsControl"
          [type]="BooleanFieldType.toggle"
          class="mat-mdc-slide-toggle_blue"
        ></base-boolean-field>
      </h4>
    </div>
    <mat-divider></mat-divider>

    <div class="settings__block">
      <h4 class="body-1 settings__title">
        <span>{{ 'BLOCK.TABLE.SETTINGS.ALLOW_CUSTOM_SELECTION_TOGGLE' | translate }}</span>
        <base-boolean-field
          [disabled]="dynamicRowsControl.value"
          [control]="allowCustomSelectionControl"
          [type]="BooleanFieldType.toggle"
          class="mat-mdc-slide-toggle_blue"
        ></base-boolean-field>
      </h4>
    </div>

    <mat-divider></mat-divider>

    <app-block-instruction-settings [isInstructionEnabled]="block.isInstructionEnabled"></app-block-instruction-settings>

    <app-block-styles-settings [settings]="block.styleSettings"></app-block-styles-settings>
  </app-settings>
</ng-template>

<app-text-editor [isViewMode]="true" [content]="block().title" class="block-common__field"></app-text-editor>
<app-text-editor
  *ngIf="block().description"
  [isViewMode]="true"
  [content]="block().description"
  class="block-common__field"
></app-text-editor>
<div class="block-table__table-container">
  <div class="block-table__table-container--inner" #tableContainerInner>
    <div class="block-table__grid" *ngIf="block().metaType?.attributes" #table>
      <div
        class="block-table__head"
        *ngFor="let attribute of columnsToDisplay(); index as attributeIndex; trackBy: trackColumn"
        [style]="'grid-column: ' + (attributeIndex + 1)"
      >
        <div class="block-table__head-inner">
          <div>
            {{ attribute.name }}
          </div>
          <mat-icon
            *ngIf="attribute.description"
            [matTooltip]="attribute.description"
            [svgIcon]="AppIcon.INFO_20"
            [class.primary]="attribute.primary"
          ></mat-icon>
        </div>
      </div>
      <ng-container *ngFor="let row of formArray.value; let index = index; trackBy: trackRow">
        <div
          class="block-table__grid-row"
          *ngFor="let attribute of columnsToDisplay(); index as attributeIndex; trackBy: trackColumn"
          [class.block-table__grid-row--rounded-left]="attributeIndex === 0"
          [class.block-table__grid-row--rounded-right]="attributeIndex === columnsToDisplay().length - 1"
          [style]="'grid-column: ' + (attributeIndex + 1)"
        >
          <div class="block-table__grid-row-inner" [ngSwitch]="attribute.type">
            <ng-container *ngSwitchCase="'string'">
              <div class="block-table__card-item-primary" *ngIf="attribute.primary">
                <app-text-editor [content]="formArray.get([index, 'value', attribute.id]).value" [isViewMode]="true"></app-text-editor>
                <button
                  *ngIf="allowCustomSelection() && !isUserInputDisabled()"
                  (click)="deleteRow(index)"
                  class="block-table__item-remove"
                  mat-icon-button
                >
                  <mat-icon [svgIcon]="AppIcon.CLOSE"></mat-icon>
                </button>
              </div>
              <ng-container *ngIf="!attribute.primary">
                <input
                  class="block-table__input"
                  *ngIf="!isUserInputDisabled() && attribute.displayMode === 'edit'; else stringElse"
                  [formControl]="$any(formArray.get([index, 'value', attribute.id]))"
                  [placeholder]="attribute.name + ' eingeben ...'"
                />
                <ng-template #stringElse>
                  <div class="block-table__text">{{ formArray.get([index, 'value', attribute.id]).value }}</div>
                </ng-template>
              </ng-container>
            </ng-container>
            <ng-container *ngSwitchCase="'boolean'">
              <label class="block-table__checkbox-label">
                <input
                  class="block-table__checkbox"
                  type="checkbox"
                  [formControl]="$any(formArray.get([index, 'value', attribute.id]))"
                  [attr.aria-label]="attribute.name"
                />
                <mat-icon class="block-table__checked" [svgIcon]="AppIcon.CHECK"></mat-icon>
                <mat-icon class="block-table__unchecked" [svgIcon]="AppIcon.CIRCLE"></mat-icon>
              </label>
            </ng-container>
            <ng-container *ngSwitchCase="'number'">
              <input
                class="block-table__input"
                *ngIf="!isUserInputDisabled() && attribute.displayMode === 'edit'; else numberElse"
                [formControl]="$any(formArray.get([index, 'value', attribute.id]))"
                [placeholder]="attribute.name + ' eingeben ...'"
              />
              <ng-template #numberElse>
                <div class="block-table__text">{{ formArray.get([index, 'value', attribute.id]).value }}</div>
              </ng-template>
            </ng-container>
            <ng-container *ngSwitchCase="'selection'">
              <mat-select
                *ngIf="!isUserInputDisabled()"
                class="block-table__selection"
                [formControl]="$any(formArray.get([index, 'value', attribute.id]))"
                [placeholder]="attribute.name + ' auswählen ...'"
                panelClass="custom-panel block-table__selection-panel"
              >
                <mat-option [value]="null"></mat-option>
                <mat-option *ngFor="let option of this.columnsToDisplay()[attributeIndex].options" [value]="option">
                  {{ option }}
                </mat-option>
              </mat-select>
              <div *ngIf="isUserInputDisabled()" class="block-table__text">{{ formArray.get([index, 'value', attribute.id]).value }}</div>
            </ng-container>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
  <div>
    <form *ngIf="allowCustomSelection() && !isUserInputDisabled()" class="block-table__card-add" (ngSubmit)="addRow()">
      <input [formControl]="customRow" [placeholder]="'Hinzufügen'" type="text" />
      <button type="submit" color="primary" mat-flat-button>
        {{ 'BUTTON_NAME.ADD' | translate }}
      </button>
    </form>
  </div>
  <app-text-editor
    *ngIf="showInstruction()"
    [isViewMode]="true"
    [content]="block().instruction"
    class="block-common__field"
  ></app-text-editor>
</div>

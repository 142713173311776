import { AbstractModel } from './_base.model';
import { Exclude, Expose, Transform } from 'class-transformer';
import { OrganizationUser } from './organization-user.model';

@Exclude()
export class Organization extends AbstractModel {
  @Expose()
  name: string;
  @Expose()
  users: OrganizationUser[];

  @Expose()
  @Transform(({ obj }) => obj.users.length)
  numberOfMembers: number;

  @Expose()
  emailOfOwner?: string;
}
